<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black font-weight-bolder">Roles{{ search }}{{ app }}</h1>
      <div>
        <!-- <button class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
          </button> -->

       
        <button
          type="button"
          class="btn btn-primary"
          style="border-radius: 16px; padding: 16px"
          @click="HandelClickNewRole"
        >
          +&nbsp; Create Role
        </button>
      </div>
    </div>
    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="rolesData.length == 0">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable
        v-model="rolesData"
        handle=".draggable-task-handle"
        tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline  cursor-pointer"
      >
        <b-card
          no-body
          class="card-revenue-budget dash-total-card d-flex mb-2 drag-el"
          v-for="(item,index) in rolesData"
          :key="index"
        >
          <b-row class="p-1">
            <b-col md="10">
            <b-row @click="$router.push(`/roles/userrole/${item.id}/${item.name}`)" class="p-1">
            <b-col md="4" class="d-flex align-items-center pl-2">
              <div>
                <div class="mb-half">Role</div>
                <div class="font-weight-bolder text-black">
                  {{ item.name }}
                </div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Number Of Users</div>
                <div class="font-weight-bolder text-black">{{ item.user_count }}</div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Last Update</div>
                <div class="font-weight-bolder text-black">{{ item.modified_at ? item.modified_at : item.created_at }}</div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Status</div>
                <div class="font-weight-bolder">
                  <div :class="item.is_active ? 'status-green-btn' : 'status-red-btn'">
                    <strong><span class="boder">{{item.is_active ? 'Active' : 'Inactive'}}</span></strong>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
            </b-col>
            <b-col
              md="2"
              class="
                d-flex
                align-items-center
                justify-content-center
                text-black
              "
            >
              <div
              :id="'popover-button-variant'+item.id"
              href="#"
                style="background: #f4f9fd; padding: 8px; border-radius: 16px"
              >
                <feather-icon icon="MoreVerticalIcon" size="25" />
              </div>
            </b-col>
          </b-row>
        <b-popover
          ref="popover"
          :target="'popover-button-variant'+item.id"
          triggers="hover"
          class="custom-popover"
        >
          <!-- <button
            @click="onPopovcerOpen(item.id)"
            class="notofication-btn"
            style="color: #408dff; margin: 5px"
            size="sm"
          >
            Cancel
          </button> -->

          <!-- <button
            type="button"
            class="btn notofication-btn"
            style="background: #f4f9fd; margin: 8px"
          >
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button> -->
          <button
            type="button"
            class="btn notofication-btn"
            @click="handelEdit(item.id)"
            style="background: rgb(63 140 254 / 13%);"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
          </svg>
          </button>
          <button
            type="button"
            class="btn notofication-btn"
            @click="handelDelete(item.id)"
            style="background-color: rgba(246, 81, 96, 0.135735);"
          >
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </b-popover>

      </b-card> 
      </draggable>
      <div class="no-results" :class="{ show: !rolesData.length }"></div>
    </div>

      <button v-if="!isLoading && rolesData.length" type="button" class="w-auto btn slide-btn">
        <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
      </button>

    <!-- <button
      v-if="popoverShow"
      id="seeall-btn"
      type="button"
      class="btn see-btn"
    >
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">Select All</p>
      </div>
    </button> -->
  </section>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
import CrudComponent from "./CrudComponent";
import useJwt from "@/auth/jwt/useJwt";
export default {
  components: {
    draggable,
    CrudComponent
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      data: {},
      permissionType:"",
      isLoading:false,
        roleName:"",
      rolesData:[],
      rolesData1:[],
      permissions:[],
      paginationStart: 0,
      pageNo: 1,
      paginationLength:0,
      paginationEnd: 10,
      // rolesData: [
      //   { name: "Super Admin" },
      //   { name: "Admin" },
      //   { name: "Date Entry" },
      //   { name: "Ad Placement / Subscription Manager" },
      //   { name: "Application User" },
      // ],
      value: "1",
    };
  },
  created() {},
  mounted(){
    setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Role and Permission')
      if(is_valid.length < 1){this.$router.push('/')}
    }, 500);
    this.getRoles();
  },
  computed: {
    search() {
      // let data = []
      let search = this.$store.state.searchData
      // data = this.rolesData1.filter(item => (item.user_name?.replace(/\s/g,'').toLowerCase().includes(search) 
      //                                              || item.id.toString().includes(search) 
      //                                              || item.email.replace(/\s/g,'').toLowerCase().includes(search) 
      //                                              || item.created.replace(/\s/g,'').toLowerCase().includes(search)
      //                                              || (item.is_active == 1 ? 'Active' : 'Inactive').replace(/\s/g,'').toLowerCase().includes(search)
      //                                              ))
      if (this.$store.state.application) {
        this.getSearch(search);
      }
      
    },
    app() {
      setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Role and Permission')
      if(is_valid.length < 1){this.$router.push('/')}
    }, 1000);
      console.log('enter')
      this.$store.state.searchData = ''
    }
  },
  methods: {
    HandelClickNewRole(){
      this.$router.push('/roles/addrole')
    },
    permissionHandelchange(value){
      console.log(value)
     this.permissionType = value
    }, 
     getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/role/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.rolesData = res.data.data
          this.paginationLength = res.data.totaldata
          this.rolesData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/role/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.rolesData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.rolesData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/role/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.rolesData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.rolesData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      this.checkBoxIds = {}
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    handelDelete(id){
      this.$http.post(`${this.baseURL}/role/delete`,{id:id},
              {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials:true
            }).then((res)=>{
              this.getRoles()
      this.$forceUpdate()
            })
    },
    handelEdit(id){
     this.$router.push(`/roles/editrole/${id}`)
    },
    async getRoles(){ 
      if (this.$store.state.application) {
      this.isLoading=true
       this.$http.post(`${this.baseURL}/role/index`, { pageno: this.pageNo,app_id: this.$store.state.application.id  }).then((res) => {
        console.log(res.data.data, "res-=-=")
            this.rolesData = res.data.data
          this.paginationLength = res.data.totaldata
          this.rolesData1 = res.data.data
          this.isLoading = false
          })
        }
    }
  },
};
</script>

<style lang="scss">
// @import "@core/scss/vue/pages/dashboard-ecommerce.scss";
// @import "@core/scss/vue/libs/chart-apex.scss";
.searchIcon{
  width: 10%
}
.searchInput{
  width: 90%
}
@media only screen and (max-width: 576px){
.searchIcon{
  width: 15%
}
.searchInput{
  width: 85%
}
}
// .modal-open .modal{
//   overflow-x: hidden !important;
//     overflow-y: hidden !important;
// }
.manualRadio{
  border: 2px solid #cfd7df;
    width: 30%;
    height: 50px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.manualradioborder{

  border: 2px solid #007bff;
}
.status-green-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.status-red-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #e20c0c;
  border: 1px solid #e20c0c;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 135px !important;
  max-width: 300px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
  padding:0.5rem 0.75rem !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
  
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>
